import React from "react";
import Header from "../components/Headers/Header";
import Footer from "../components/Footer";
import { auth } from "../config/firebase";
import HeaderAuth from "../components/Headers/HeaderAuth";

export default function PageTemplate({ title, content }) {
  return (
    <div>
      {auth.currentUser ? <Header /> : <HeaderAuth transparent />}
      <div className="py-10 mx-auto w-11/12 md:w-4/6 min-h-[50vh]">
        <h1 className="font-regular text-2xl">{title}</h1>
        <div className="mt-5">{content}</div>
      </div>
      <Footer />
    </div>
  );
}
