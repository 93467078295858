import React from "react";
import PageTemplate from "../templates/PageTemplate";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const Card = ({ title, desc, img, link }) => (
    <div
      onClick={() => navigate(`${link}`)}
      className="cursor-pointer p-5 border border-gray-300 rounded-md flex flex-row items-center"
    >
      <img
        style={{
          height: 100,
          width: 100,
        }}
        src={img}
        resizeMode={"contain"}
      />
      <div className="ml-3">
        <h4 className="text-xl">{title}</h4>
        <span className="text-sm text-gray-500">{desc}</span>
      </div>
    </div>
  );

  function sendVerificationEmail() {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        toast.success(`L'e-mail de vérification vous a été envoyé`);
      })
      .catch((error) => {
        if (error.code == "auth/too-many-requests") {
          toast.error(
            `Une erreur est survenue: Vous avez fait trop de demandes, veuillez réessayer ultérieurement`
          );

          return;
        }
        toast.error(`Une erreur est survenue: ${error}`);
      });
  }

  return (
    <PageTemplate
    title={t('page_title')}
    content={
      <div className="">
        <div className="mb-10">
          {!auth?.currentUser?.emailVerified && (
            <div
              onClick={sendVerificationEmail}
              className="mx-4 border border-yellow-500 bg-yellow-50 rounded-md mb-3 p-2 flex-row items-center justify-between"
            >
              <div className="flex flex-row items-center gap-2">
                <img
                  style={{
                    height: 50,
                    width: 50,
                  }}
                  src={require("../assets/img/email.png")}
                  resizeMode={"cover"}
                />
                <p className="flex-1 text-sm text-yellow-700">
                  {t('email_verification_message')}{" "}
                  <span className="flex-1 text-sm text-yellow-700">
                    {t('email_link', { email: auth?.currentUser?.email })}
                  </span>{" "}
                  {t('email_verification_text')} <br />
                  <span className="flex-1 text-sm text-yellow-700 underline">
                    {t('no_link_received')}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="grid md:grid-cols-2 gap-4">
          <Card
            title={t('search_vehicle_title')}
            desc={t('search_vehicle_desc')}
            img={require("../assets/img/plus.png")}
            link={"/publier-une-recherche"}
          />
          <Card
            title={t('found_vehicle_title')}
            desc={t('found_vehicle_desc')}
            img={require("../assets/img/search.png")}
            link={"/j-ai-trouver-un-vehicule"}
          />

          <Card
            title={t('my_searches_title')}
            desc={t('my_searches_desc')}
            img={require("../assets/img/find.png")}
            link={"/mes-recherches"}
          />

          <Card
            title={t('my_findings_title')}
            desc={t('my_findings_desc')}
            img={require("../assets/img/find.png")}
            link={"/mes-trouvailles"}
          />

          <Card
            title={t('notifications_title')}
            desc={t('notifications_desc')}
            img={require("../assets/img/notification.png")}
            link={"/notifications"}
          />
          <Card
            title={t('my_profile_title')}
            desc={t('my_profile_desc')}
            img={require("../assets/img/femme.png")}
            link={"/mon-profil"}
          />
          <Card
            title={t('my_rib_title')}
            desc={t('my_rib_desc')}
            img={require("../assets/img/card.png")}
            link={"/mon-rib"}
          />
        </div>
      </div>
    }
  />
  );
}
